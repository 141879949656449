// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const devURLs ={

  // vmedproURL: 'https://vmedproqaukt.dhanushinfotech.com',
  // vmedproURL: 'https://vmedprodevukt.dhanushinfotech.com',
  // vmedproURL: 'https://vmedproqa.dhanushinfotech.com',
  // vmedproURL: 'https://vmedproqa2.dhanushinfotech.com'
  // vmedproURL: 'https://vmedprodev2.dhanushinfotech.com'
  // vmedproURL: 'https://vmedproqa2.dhanushinfotech.com',
  // vmedproURL: 'https://vmedprodevgam.dhanushinfotech.com',
  // vmedproURL: 'https://vmedprodevayush.dhanushinfotech.com',
  // vmedproURL: 'https://vmedproqaayush.dhanushinfotech.com',
  // vmedproURL: 'vmedprodevnandghar.dhanushinfotech.com',
  // vmedproURL: 'https://tsivmedprodev.dhanushinfotech.com',
  // vmedproURL: 'https://vmedprodev.dhanushinfotech.com',
  // vmedproURL: 'https://vmedproqaapgic.dhanushinfotech.com'
  // vmedproURL: 'https://vmedprodevapgic.dhanushinfotech.com'
  // vmedproURL: 'vmedproqaayush.dhanushinfotech.com'
  // vmedproURL: 'https://vmedprodevretail.dhanushinfotech.com',
    //  vmedproURL: 'https://vmedproqaretail.dhanushinfotech.com'
    // vmedproURL: 'https://vmedprodevarogyarath.dhanushinfotech.com'
    // vmedproURL: 'https://vmedproqaarogyarath.dhanushinfotech.com'
      //  vmedproURL: 'https://vmedpromicrositesqa.dhanushhealthcare.com'
    // vmedproURL: 'https://vmedpromicrositesdev.dhanushhealthcare.com'
    // vmedproURL: 'https://livestocktmdev.dhanushinfotech.com'
    // vmedproURL: 'https://livestocktmqa.dhanushinfotech.com'
    vmedproURL: 'https://vmedproqahealthandwellness.dhanushhealthcare.com'
}

const webUrl = location.origin.toLowerCase();
export const environment = {
  production: false,
  loginAPI: 'vidmed-login/',
  registrationAPI: 'registration/jwt/',
  registration_API: 'registration/',
regUpdtAPI: 'registration/jwt/updatestatus/',
  fileServiceAPI: 'file-service/jwt/file/',
  orgfileServiceAPI: 'org-fileservice/jwt/file/',
  pocAPI: 'poc-emr/jwt/doctor/poc/',
  pharmacyAPI:'pharma/',
  labAPI:'lab/',
  presAPI: `poc-emr/jwt/`,
  mastersAPI: 'masters/',
  masterUrl:'rakthadan-masters/',
  apiUrl:'sevaBharati/',
  userApi: 'user/',
  agentApi: 'agent/',
  reportApi: 'vidmed-reports/jwt/',
  notificationApi: 'notifications/',
  pocAyushAPI: 'poc-emr-ayush/doctor/poc/',
  prescription: 'prescription',
  labprescription: 'poc-emr/',
  ecgText: 'ecgreport/',
  audioText: 'audiofile/',
  organizationFilePath: 'vidmed-orgfiles/ORGANIATION/',
  viewFilePath: 'vidmedfiles/',
  applicationURL: devURLs.vmedproURL,
  tenantURL: devURLs.vmedproURL+'/',
  payubiz:' https://test.payu.in/_payment',
  axaApi:'axa/',
  tenantConfig:{
    isUKT:'HUB_&_SPOKE'
  },
  // Dev Power BI URL 24-04-2023
  // POWERBIURL : 'https://app.powerbi.com/view?r=eyJrIjoiNzIzNjYyZWUtNGZhMy00NDU5LWFmZGYtNzE3ZWIwMmE3YjVmIiwidCI6ImQwOThhNTc2LWJmM2MtNDY3NC04ZjRiLWYxYWU0NjUzYTlmNCJ9',
  //  Production Power BI 24-04-2023
  // POWERBIURL   : 'https://app.powerbi.com/view?r=eyJrIjoiZjIyOGZkMTQtMDk1OS00OGQ2LWIzNjgtMjQzYjRiZWRmOWE5IiwidCI6ImQwOThhNTc2LWJmM2MtNDY3NC04ZjRiLWYxYWU0NjUzYTlmNCJ9',
  // QA POWERBI URL 24-04-2023
  // POWERBIURL:'https://app.powerbi.com/view?r=eyJrIjoiZDQyZGUxN2UtN2NiMC00YWI3LWIzNmUtNWI1NWUzNDNlMDYyIiwidCI6ImQwOThhNTc2LWJmM2MtNDY3NC04ZjRiLWYxYWU0NjUzYTlmNCJ9',
  // Training
  // applicationURL:'https://telemedicine-uk-training.vmedtm.com',
  // tenantURL: 'https://telemedicine-uk-training.vmedtm.com/',
  // applicationURL: 'https://doctors.uktelemedicine.in',
  // tenantURL: 'https://doctors.uktelemedicine.in/',
  logoPath: devURLs.vmedproURL+'/logos/vidmed_2.0/',
  downloadPath: 'file-service/file/download',
  bgLogin: '/Web_Login_Bg.jpg',
  bgLoginNew: '/Web_Login_Bg_new.png',
  background: '/backgound.png',
  appicon:'/appicon.ico',
  logo: '/logo.png',
  logo1: '/logo1.png',
  lgnLogo1: '/web_login_logo1.png',
  lgnLogo2: '/web_login_logo2.png',
  logo_prescrption: '/logo_prescrption.png',
  assetsURL: '/assets/vmLogos/',
  andrdLink: 'https://play.google.com/store/apps/details?id=in.ap.orgdhanush.vidmedin',
  appleLink: 'https://apps.apple.com/us/app/id1522161727',
  vdoUM: '/assets/demo/YVS_Demo_vdo.mp4',
  docUM: 'assets/demo/AgentModule_BRD.doc',
  adminUM: 'https://vidmed2.dhanushinfotech.com/media/Admin_UserManual_V1.0.docx',
  doctorUM: 'https://vidmed2.dhanushinfotech.com/media/Dhanush_Vidmed_Doctor_Manual_V5.0.docx',
  docHomeVdo : 'https://vidmed2.dhanushinfotech.com/media/Doctor_Login_Demo_Video_Vidmed2.0.mp4',
  docConsVdo : 'https://vidmed2.dhanushinfotech.com/media/Doctor_Patient_Consultation_Video_Vidmed2.0.mp4',
  tutorialUrl :'https://uktelemedicine.in/index.php/help/',
  isLocal: webUrl.includes('localhost'),
  isGNM: webUrl.includes('genome'),
  // webSrce: (webUrl.includes('sage') ? 'sage' : (webUrl.includes('doctoranh') ? 'anh' : (webUrl.includes('genome') ? 'genome' : (webUrl.includes('incipe') ? 'incipe' : (webUrl.includes('swashodhan') ? 'swashodhan' : (webUrl.includes('esic') ? 'esic' : (webUrl.includes('kampe') ? 'kampe' : (webUrl.includes('samaritan') ? 'samaritan' : (webUrl.includes('aarogyamplus') ? 'sewa' : (webUrl.includes('esanjeevani') ? 'esanjeevani' : 'dhanush')))))))))),
  webSrce: ((webUrl.includes('telemedicine-uk-qa')) ? 'ukt': ((webUrl.includes('telemedicine-uk-training')) ? 'ukt':((webUrl.includes('telemedicine-uk-dev')) ? 'ukt': ((webUrl.includes('doctors.uktelemedicine.in')) ? 'ukt': ((webUrl.includes('telemedicine.vmedtm.com') || webUrl.includes('qatelemedicine.vmedtm.com')) ? 'chs': ((webUrl.includes('ccmc.vmedtm.com') || webUrl.includes('qaccmc.vmedtm.com')) ? 'coimbatore' : ((webUrl.includes('vaccination.uferwas.in') || webUrl.includes('qavaccination.uferwas.in')) ? 'uferwas': (webUrl.includes('sage') ? 'sage' : (webUrl.includes('doctoranh') ? 'anh' : (webUrl.includes('genome') ? 'genome' : (webUrl.includes('incipe') ? 'incipe' : (webUrl.includes('swashodhan') ? 'swashodhan' : (webUrl.includes('esic') ? 'esic' : (webUrl.includes('kampe') ? 'kampe' : (webUrl.includes('samaritan') ? 'samaritan' : ((webUrl.includes('eclinic234') || webUrl.includes('qadoctors.eclinic234.com')) ? 'eclinic' : (webUrl.includes('aarogyamplus') ? 'sewa' : (webUrl.includes('ccad-doctor') ? 'ccad' : (webUrl.includes('esanjeevani') ? 'esanjeevani' :
  (webUrl.includes('eSubcenter') ? 'esubcenter' : ((webUrl.includes('ng.vidmed') || webUrl.includes('ngqa.vidmed')) ? 'nandghar'  : ((webUrl.includes('germanium.vidmed') || webUrl.includes('germaniumqa.vidmed')) ? 'germanium':
  ((webUrl.includes('abhayasahaya.vidmed') || webUrl.includes('qaabhayasahaya.vidmed')) ? 'abhaya':  ((webUrl.includes('tngcc.vidmed') || webUrl.includes('qatngcc.vidmed')) ? 'tngcc': ((webUrl.includes('hcs.org.in') || webUrl.includes('hcsqa')) ? 'hcs' : ((webUrl.includes('salem.vmedtm.com') || webUrl.includes('qasalem.vmedtm.com')) ? 'salem': ((webUrl.includes('gam.vmedtm.com') || webUrl.includes('qagam.vmedtm.com')) ? 'gam': ((webUrl.includes('nwhtm1.nwh.co.ke'))  ? 'nwh': ((webUrl.includes('janahitha.rakthadaanbharathi.org'))  ? 'janahitha': 'dhanush'))))))))))))))))))))))))))))),
  lgnUsrBoxTxt: (webUrl.includes('sage') ? '' : (webUrl.includes('doctoranh') ? '' : (webUrl.includes('genome') ? '' : (webUrl.includes('incipe') ? '' : (webUrl.includes('swashodhan') ? '' : (webUrl.includes('esic') ? '' : (webUrl.includes('kampe') ? '' : (webUrl.includes('samaritan') ? '' : (webUrl.includes('eclinic234') ? '' : 'Telehealth Application'))))))))),
  lclWebSrce: 'samaritan',
  // IND
  mobPtrnIND: '^[6-9]{1}[0-9]{9}$',
  maxLngthIND: '10',
  mobErrMsgIND: 'should be 10 digits & starts with 6,7,8,9',
  mobValdValIND: 6,
  // tollFreeIND: '1800 572 3055',
  tollFreeIND: '6303004545',
  // SAGE
  mobPtrnSAGE: '^([0-9]){9}$',
  maxLngthSAGE: '9',
  mobErrMsgSAGE: 'should be 9 digits & does not starts with 0',
  mobValdValSAGE: 0,
  tollFreeSage: '+254725448429',
  isSAGE: webUrl.includes('sage'),
  // NIGERIA
  mobPtrnNIG: '^[0]{1}[0-9]{10}$',
  maxLngthNIG: '11',
  mobErrMsgNIG: 'should be 11 digits & starts with 0',
  mobValdValNIG: 0,
  tollFreeNIG: '1234 567 8910',
  isNIG: webUrl.includes('eclinic234'),
  isEclnc: webUrl.includes('doctors.eclinic234'),
  isKMP: webUrl.includes('kampe'),
  isSMR: webUrl.includes('samaritan'),
  isNdGhr: webUrl.includes('ng.vidmed'),
  tollFreeKMP: '0700 325 4642',
  tollFreeSMR: '0809 1000 990',
  tollFreeSJN: '8309634283',

  //Nandghar tollfree
  tollFreeNDGHR: '18005729329',
  //Abhaya tollfree
  tollFreeABH: '8567856754',
  //HCS
  tollFreeHCS: '+91 9988939939',
  //Salem TollfreeNo
  tollFreeSalem:'0427-2212960',
  //Janahitha Rakthdaan Tollfree
  tollFreeJanahitha:'9030339229',

  DATEFORMAT:{
    YEARFORMAT:'YYYY-MM-DD',
    MONTHFORMAT:'DD-MM-YYYY'
  },

    // docs
  nigDocs: [{
    ecSAUM: 'https://doctors.eclinic234.com/privacy/eCLINIC234_SuperAdmin_Manual.pdf',
    ecADUM: 'https://doctors.eclinic234.com/privacy/eCLINIC234_Admin_Manual.pdf',
    ecDRUM: 'https://doctors.eclinic234.com/privacy/eCLINIC234_Doctor_Manual.pdf',
    ecSAVD: 'https://doctors.eclinic234.com/privacy/Superadmin.mp4',
    ecADVD: 'https://doctors.eclinic234.com/privacy/admin.mp4',
    ecDRVD: 'https://doctors.eclinic234.com/privacy/doctor.mp4',
    smDRVD: 'https://samaritan.eclinic234.com/privacy/doctor.mp4',
    kpDRVD: 'https://kampe.eclinic234.com/privacy/doctor.mp4',
    smDRUM: 'https://samaritan.eclinic234.com/privacy/Samaritan_Doctor_Manual.pdf',
    kpDRUM: 'https://kampe.eclinic234.com/privacy/Kampe_Doctor_Manual.pdf',
    // eClinicAV: '/privacy/Admin_eclinic.mp4',
    // eClinicSAV: '/privacy/Superadmin_eCLINIC.mp4',
  }],
  securityKey:'VIDDHANU@!@#',
    // docs
  // TEST
  test: `${location.href.includes('sage') ? '' : ''}`,
  test2: `${webUrl.includes('localhost') ? 'Local' : 'Non-Local'}`,
};
/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.